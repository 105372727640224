@use "sass:math";
@import "~evostrap/evostrap-core/src/css/functions";
@import "~evostrap/evostrap-core/src/css/variables";
//========================================================================================

/*                                                                                      *
 *                             Personnalisation CSS MEPS                                *
 *                                                                                      */

//========================================================================================


//──── search assistants ────────────────────────────────────────────────────────────────
#search-assistants .form-control {
    line-height: 1.7em;
}

//──── table assistants ────────────────────────────────────────────────────────────────
#table-assistants th {
    font-weight: normal;
    font-size: 1em;
}

[class^="erpl_assistant-icon"] {
    width: 30px;
    height: 26px;
    min-width: 30px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

#table-assistants thead th {
    border-bottom: 0px;
    background: #f0f0f0;
    font-weight: bold!important;
    font-size: 1.2em;
    width: 33% !important;
}

@media screen and (max-width: 639px) {
    #table-assistants td {
        padding-right: 12px!important;
    }
    #table-assistants tr td:first-child {
        background: #f0f0f0;
        font-weight: bold;
        font-size: 1.2em;
    }
    #table-assistants tbody td {
        display: block!important;
        text-align: center;
    }
    #table-assistants tbody td:before {
        content: attr(data-th);
        display: block!important;
        text-align: center;
    }
    #table-assistants th {
        font-weight: bold !important;
        font-size: 1.2em;
        text-align: center;

        &:before {
            margin-right: 0;
            display: block;
        }
    }
}

@media screen and (min-width: 1020px) {
    #table-assistants .erpl_type {
        width: 40%!important;
    }
}

.erpl_assistant-container {
    display: inline!important;
}

.erpl_assistant {
    padding-top: 4px;
}

.erpl_assistant span {
    padding-top: 3px;
}

.erpl_type-assistants-item {
    display: inline-flex!important;
}

//──── icons type of assistant ────────────────────────────────────────────────────────────────
.accredited_assistants,
.assistant-accredited {
    background-image: url("../../img/accredited-assistant.svg");
    background-repeat: no-repeat !important;
}

.assistant-quaestor {
    background-image: url("../../img/assistant-quaestor.svg");
    background-repeat: no-repeat !important;
}

.assistants_vice_presidency,
.assistant-vice-presidency {
    background-image: url("../../img/assistant-vice-presidency.svg");
    background-repeat: no-repeat !important;
}

.local_assistants,
.local-assistant {
    background-image: url("../../img/local-assistant.svg");
    background-repeat: no-repeat !important;
}

.service_providers,
.service-provider {
    background-image: url("../../img/service-provider.svg");
    background-repeat: no-repeat !important;
}

.paying_agent,
.paying-agent {
    background-image: url("../../img/paying-agent.svg");
    background-repeat: no-repeat !important;
}

.trainee {
    background-image: url("../../img/trainee.svg");
    background-repeat: no-repeat !important;
}

//──── type of assistant : legend ────────────────────────────────────────────────────────────────
.erpl_assistants-legend-item {
    margin-bottom: 20px;
}

.erpl_assistants-legend-heading {
    margin-bottom: 10px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

//──── info ────────────────────────────────────────────────────────────────
.erpl_assistants-info {
    margin-bottom: 10px;
}

//──── erpl move ────────────────────────────────────────────────────────────────
@media screen and (max-width: 1019px) {
    #sectionsNavPositionRwd {
        margin-bottom: 2em;
    }
}

//========================================================================================

/*                                                                                      *
 *                                   MEPS CARD                                          *
 *                                                                                      */

//========================================================================================
//──── PRESENTATION MEP ────────────────────────────────────────────────────────────────
//────  photo MEP ──────────────────────
@media (min-width: 0px) and (max-width: 479px) {
    #presentationmep .erpl_image-frame {
        width: 50%!important;
        margin-left: auto!important;
        margin-right: auto!important;
    }
}

#presentationmep .separator {
    height: auto!important;
}

//────  icon listen ──────────────────────
.erpl_mep-mp3 {
    background-image: url('../../img/listen.svg') !important;
    background: no-repeat;
    background-position: 0px 2px;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    display: inline-block;
}

.erpl_mep-mp3:hover {
    background-image: url('../../img/listen-hover.svg') !important;
    background: no-repeat;
    background-position: 0px 2px;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    cursor: pointer;
}

//──── DETAILED MEP CARD ────────────────────────────────────────────────────────────────
//──── status meps ─────────────────────────────
.erpl_meps-status {
    margin-top: 2em;

    ul:not(.es_select-options) {
        line-height: 2em;

        li {
            margin-left: -25px;
        }
    }

    
}



//──── activity meps ─────────────────────────────
.erpl_meps-activity {
    position: relative;
    padding-top: 13px;
    padding-bottom: 8px;
}

.erpl_meps-activity .erpl_title-h3 a {
    color: $gray-900!important;
}

.erpl_meps-activity .erpl_title-h3 a:hover {
    color: $primary!important;
}

.erpl_meps-activity a .erpl_title-h3 {
    color: $gray-900!important;
}

.erpl_meps-activity a:hover .erpl_title-h3 {
    color: $primary!important;
}

.erpl_meps-activity .erpl_subtitle {
    color: $gray-900!important;
    margin-top: 5px;
    margin-bottom: 5px;
}

#cv .erpl_meps-activity li {
    margin-left: -24px;
    margin-bottom: 6px;
}

.erpl_description p {
    margin-bottom: 0px;
}

.erpl_committee {
    font-size: 1.25rem;
    font-family: Georgia, Garamond, serif;
    vertical-align: middle;
    max-width: 90%;
    display: inline-block;
}

.erpl_language-icon {
    padding: 3px 4px 1px 2px !important;
    background-color: $white;
    border-width: 1px;
    color: #003399 !important;
    font-size: 0.5em;
    font-family: Helvetica, Arial, sans-serif;
    border-style: solid;
    min-width: 14px;
    text-align: center;
    margin-left: 1px;
    line-height: normal;
    position: relative;
    top: -3px;
}

//──── list of doc ──────────────────────────────
.erpl_docs-separator {
    border-left: 1px solid $gray-700;
    margin-left: 6px;
    margin-right: 10px;
    vertical-align: top;
}

.erpl_group-doc i.erpl_icon-doc {
    display: inline-block;
    margin-right: 5px;
}



//──── newshub ──────────────────────────────
.erpl_newshub-card {
    background: linear-gradient(to right, $gray-200, #e4e5e6);
    border: 1px solid $gray-300 !important;
}

.erpl_newshub-card-header {
    background: linear-gradient(to right, $gray-200, #e4e5e6);
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.erpl_newshub-photomep {
    border-radius: 50%;
    border: 1px solid $gray-300;
    background-position: 10% 20%!important;
}

.erpl_newshub-card-body {
    background: linear-gradient(to right, $gray-200, #e4e5e6);
    flex: 1 1 auto;
    padding: 1.25rem;
}

.erpl_newshub-card-item {
    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 30px;
    padding-right: 12px;
    background-color: $white !important;
    margin-bottom: 1.25rem !important;
    border: 1px solid $gray-300 !important;
}

.erpl_newshub-card-info {
    float: right;
    font-size: 85.71429%;
    font-weight: 400;
}

.erpl_newshub-card-item .erpl_newhub-twitter {
    margin-left: 8px;
}

.erpl_newshub-card-item .erpl_newhub-twitter::before {
    background-image: url('../../img/meps-icons.svg') !important;
    background-repeat: no-repeat;
    content: "";
    vertical-align: middle;
    display: inline-block;
    width: 26px;
    height: 24px;
    border-left: 1px solid $gray-300;
    background-position: -167px -140px;
}

.erpl_newshub-card-item .erpl_newhub-facebook {
    margin-left: 8px;
}

.erpl_newshub-card-item .erpl_newhub-facebook::before {
    background-image: url('../../img/meps-icons.svg') !important;
    background-repeat: no-repeat;
    content: "";
    vertical-align: middle;
    display: inline-block;
    width: 26px;
    height: 20px;
    border-left: 1px solid $gray-300;
    background-position: -169px -43px;
}

@media (min-width: 0px) and (max-width: 479px) {
    .erpl_newshub-card-header .erpl_image-frame {
        width: 50%!important;
        margin: auto;
    }
}

@media (min-width: 0px) and (max-width: 479px) {
    .erpl_newshub-card-header {
        text-align: center;
    }
}

//──── portfolio quaestor / VP ──────────────────────────────
.erpl_portfolio ul {
    line-height: 1.9em;
}
 
//──── MEP : all activities  ───────────────────────
#bannerbggrey .erpl_title-h3 {
    line-height: 1.4em;
}

@media (min-width: 0px) and (max-width: 480px) {
    #bannerbggrey .erpl_title-h3 {
        text-align: center;
    }
}

@media (min-width: 0px) and (max-width: 479px) {
    #bannerbggrey .erpl_image-frame {
        width: 50%!important;
        margin-left: auto!important;
        margin-right: auto!important;
    }
}